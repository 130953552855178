<template>
  <div class="info-fault-wrapper">
    <ListItem title="故障等级：">
      <i slot="icon">*</i>
      <div class="select-box" slot="value" @click="
          () => {
            query && query.faultCanEidt != 'false' ? (showPicker = true) : ''
          }
        ">
        <span v-if="faultLevel" style="margin-right: 0.6rem">{{
          faultLevel
        }}</span>
        <span v-if="!faultLevel"> 请选择 </span>
        <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing" v-if="!faultLevel"></i>
      </div>
    </ListItem>
    <ListItem title="故障原因：" :borderBottom="false">
      <i slot="icon">*</i>
    </ListItem>
    <div class="fault-reason-box">
      <van-field v-model="faultReason" rows="2" autosize type="textarea" maxlength="100" placeholder="请输入内容" show-word-limit :disabled="query && query.faultCanEidt == 'false'" />
    </div>
    <div class="upload-img-box">
      <div class="title">照片：<span v-show="fileList.length!='0'||query && query.faultCanEidt != 'false'">({{fileList.length+'/'+3}})</span></div>
      <div class="upload-content">
        <van-uploader v-model="fileList" :after-read="uploadImg" :show-upload="query && query.faultCanEidt != 'false'" :deletable="query && query.faultCanEidt != 'false'" :disabled="query && query.faultCanEidt == 'false'" />
      </div>
    </div>
    <div class="van-picker-box">
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import ListItem from '@/components/ListItem'
import { Field, Uploader, Picker, Popup } from 'vant'
import { imgUrl, QiNiuUrl } from '@/config/env'
import { getEnumsDetails, getQiNiuToken } from '@/api/common'

Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Field)
Vue.use(Popup)
export default {
  name: 'InfoFault',
  components: {
    ListItem,
  },
  data() {
    return {
      activeNames: [],
      faultLevelArr: [
        { value: '1', label: '一般故障' },
        { value: '2', label: '严重故障' },
        { value: '3', label: '紧急故障' },
        { value: '4', label: '其他故障' },
      ],
      faultReason: '',
      fileList: [],
      columns: ['一般故障', '严重故障', '紧急故障', '其他故障'],
      showPicker: false,
      faultLevel: '',
      faultLevelName: '',
      token: '',
      query: null,
    }
  },
  props: {
    detailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.query = this.$route.query
    })
  },
  watch: {
    detailInfo: {
      handler(val) {
        if (val) {
          this.faultLevel = val.faultLevelName
          // this.faultLevelName = val.faultLevelName
          this.faultReason = val.faultReason
        }
        if (val.faultPhoto) {
          let list = []
          list = val.faultPhoto.split(',')
          list.map((item) => {
            this.fileList.push({
              hash: item,
              url: 'https://photo.device.jiot.link/' + item,
              isImage: true,
            })
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getQiNiuToken()
    // this.getEnumsDetails()
  },
  methods: {
    /**
     * 获取故障等级
     */
    getEnumsDetails() {
      getEnumsDetails(3).then((res) => {
        if (res.retCode === 1) {
          this.columns = []
          res.details.content.forEach((item) => {
            this.columns.push(item.label)
          })
          this.faultLevelArr = res.details.content
        }
      })
    },
    onConfirm(value, index) {
      this.faultLevel = value
      this.showPicker = false
      console.log(this.faultLevel)
    },
    /**
     * 获取七牛云token
     */
    getQiNiuToken() {
      getQiNiuToken().then((res) => {
        this.token = res.qiniuToken
      })
    },
    /**
     * 上传图片
     */
    uploadImg(file) {
      let content = file.file
      let data = new FormData()
      data.append('file', content)
      data.append('token', this.token)
      axios.post(QiNiuUrl, data).then((res) => {
        const data = res.data
        this.fileList.pop()
        this.fileList.push({
          url: imgUrl + data.hash,
          isImage: true,
          hash: data.hash,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.info-fault-wrapper {
  background-color: white;
  /deep/.list-item-wrapper {
    @include wh(100%, 2.5rem);
  }
  i {
    color: red;
  }
  .select-box {
    span {
      font-size: 0.7rem;
      color: #999999;
    }
    i {
      color: #999999;
      font-size: 0.8rem;
    }
  }
  .fault-reason-box {
    box-sizing: border-box;
    padding: 0rem 1rem 0.5rem 1rem;
    /deep/.van-field {
      padding: 0.5rem 1rem;
      background-color: #f1f1f1;
    }
  }
  .upload-img-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem 1rem 1rem 1rem;
    .title {
      font-size: 0.8rem;
      color: black;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }
    /deep/.van-uploader__preview {
      .van-image {
        @include wh(3rem, 3rem);
      }
      i.van-uploader__preview-delete {
        color: #ce2324;
      }
    }
    /deep/.van-uploader__upload {
      @include wh(3rem, 3rem);
      background-color: #f2f2f2;
    }
  }
}
</style>
